import React from 'react';
import PropTypes from 'prop-types';
import ConversationMessages from './ConversationMessages';
import ConversationSidebar from './ConversationSidebar';
import '../styles/Conversation.scss';


class Conversation extends React.Component {
  render() {
    return (
      <div className="conversation">
        <ConversationMessages
          conversationId={this.props.conversationId}
        />

        {this.props.conversationId && (
          <ConversationSidebar conversationId={this.props.conversationId} />
        )}

      </div>
    );
  }
}

Conversation.propTypes = {
  conversationId: PropTypes.string.isRequired
};

export default Conversation;
