import React from 'react';
import ConversationTags from './ConversationTags';
import '../styles/ConversationSidebar.scss';

class ConversationSidebar extends React.Component {
  render() {
    return (
      <div className="conversation-sidebar">
        <ConversationTags conversationId={this.props.conversationId} />
      </div>
    );
  }
}

export default ConversationSidebar;
