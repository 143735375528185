import { combineReducers } from 'redux';
import user from './user';
import messages from './messages';
import conversations from './conversations';
import activeConversationId from './activeConversationId';


export default combineReducers({
  user,
  messages,
  conversations,
  activeConversationId
});
