import React from 'react';
import { connect } from 'react-redux';
import {
  Icon,
  Label,
  Modal,
  Header,
  Button,
  Checkbox
} from 'semantic-ui-react';
import _ from 'lodash'
import axios from 'axios';
import empty from 'is-empty';
import PropTypes, { string } from 'prop-types';
import '../styles/ConversationTags.scss';

class ConversationTags extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

      // System tags
      tags: [],

      // Tags that are associated with the conversation
      associatedTags: [],

      // Tags that are checked off from the form
      formSelectedTags: [],

      // This controls whether the tag modal is open or closed
      isTagModalOpen: false
    }
    this.getTags = this.getTags.bind(this);
    this._getSystemTags = this._getSystemTags.bind(this);
    this.associateTags = this.associateTags.bind(this);
    this.handleModalOpen = this.handleModalOpen.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);

    window.ReactComponentBridge__ConversationTags = this;
  }

  async componentDidMount() {
    await this.getTags();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.conversationId != this.props.conversationId) {
      this.getTags();
    }
  }

  /**
   * Get tags for the active conversation
   */
  async getTags() {
    let response = await axios
      .get(`${process.env.REACT_APP_IFSCAPITAL_TWILIO_API_ENDPOINT}/sms/tags/conversation/${this.props.conversationId}`, {
        withCredentials: true
      })
      .catch((err) => { console.log(err) });

    if (response.status === 200) {
      const associatedTags = response.data.data.map((tagRow) => {
        return {
          id: tagRow.tag.id,
          name: tagRow.tag.name
        }
      });

      let systemTags = await this._getSystemTags();

      this.setState({
        associatedTags,
        tags: _.differenceBy(systemTags, associatedTags, 'id')
      });
    }
  }

  async _getSystemTags() {
    const response = await axios
      .get(`${process.env.REACT_APP_IFSCAPITAL_TWILIO_API_ENDPOINT}/sms/tags`)
      .catch((err) => {
        console.log(err);
      })

    if (response.status === 200) {
      return response.data.data
    }

    return [];
  }

  associateTags() {
    let shouldRemoveConversation = false;
    const tagIds = this.state
      .formSelectedTags
      .filter((tag) => {
        if (tag.name == "in progress" || tag.name == "not interested") shouldRemoveConversation = true;
        return _.findIndex(this.state.tags, tag) !== -1;
      })
      .map((tag) => tag.id);

    if (!empty(tagIds)) {
      axios
      .post(`${process.env.REACT_APP_IFSCAPITAL_TWILIO_API_ENDPOINT}/sms/conversation/tags`, {
        conversation_id: this.props.conversationId,
        tags: tagIds
      }, {
        withCredentials: true
      })
      .then((response) => {
        if (response.status === 201) {
          this.setState(prevState => {
            return {
              associatedTags: [
                ...prevState.associatedTags,
                ...prevState.formSelectedTags
              ],
              tags: _.differenceBy(
                prevState.tags,
                [
                  ...prevState.associatedTags,
                  ...prevState.formSelectedTags
                ],
                'id'
              ),
              formSelectedTags: [],
              isTagModalOpen: false
            }
          }, () => {
            if (shouldRemoveConversation) {
              this.props.removeMessagesConversation(this.props.conversationId);
              this.props.setActiveConversationId(null);
            }
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
    }
  }

  handleModalOpen() {
    this.setState({ isTagModalOpen: true });
  }

  handleModalClose() {
    this.setState({
      isTagModalOpen: false,
      formSelectedTags: []
    });
  }

  render() {
    return (
      <div className="conversation-tags">
        <div className="header">
          <label>Tags</label>
          <Icon name="add circle" onClick={this.handleModalOpen} />
        </div>

        {this.state.associatedTags.map((associatedTag, idx) => {
          return (
            <Label
              className="tag-item"
              color="teal"
              key={`associated-tag-${idx}`}
            >
              {associatedTag.name}
            </Label>
          )
        })}

        <Modal
            className="modal-conversation-tags-select"
            open={this.state.isTagModalOpen}
            size='tiny'
            onClose={this.handleModalClose}
            onOpen={this.handleModalOpen}
        >
          <Header icon>
            Add tags to this conversation
          </Header>
          <Modal.Content>
            {!empty(this.state.tags) ? this.state.tags.map((tag) => {
              return (
                <div className="mt-sm">
                  <Checkbox
                    id={_.replace(_.lowerCase(tag.name), " ", "-")}
                    key={`system-tag-list-${tag.id}`}
                    label={tag.name}
                    value={tag}
                    onChange={(e, { checked, value }) => {
                      this.setState(prevState => {
                        let { formSelectedTags } = prevState;

                        if (checked) {
                          formSelectedTags.push(value);
                        } else {
                          // Remove un checked tag
                          _.pullAllBy(formSelectedTags, [value], "id");
                        }

                        return {
                          formSelectedTags
                        };
                      })
                    }}
                  />
                </div>
              )
            }) : (
              <p>No available tags</p>
            )}
          </Modal.Content>
          <Modal.Actions>
          <Button
            color='black'
            onClick={this.handleModalClose}
          >
            Close
          </Button>
          <Button
            className="add-tag-btn"
            content="Add"
            labelPosition='right'
            icon='checkmark'
            positive
            disabled={empty(this.state.formSelectedTags)}
            onClick={this.associateTags}
          />
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}

ConversationTags.propTypes = {
  conversationId: PropTypes.string.isRequired
}

const mapDispatchToProps = (dispatch) => {
  return {
    removeMessagesConversation: (conversationId) => dispatch({ type: "REMOVE_MESSAGES_CONVERSATION", payload: conversationId }),
    setActiveConversationId: (conversationId) => dispatch({ type: "SET_ACTIVE_CONVERSATION_ID", payload: conversationId })
  }
}

export default connect(null, mapDispatchToProps)(ConversationTags);
