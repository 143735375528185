import axios from 'axios';


export function login(email, password) {
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.REACT_APP_IFSCAPITAL_TWILIO_API_ENDPOINT}/user/login`, {
          email: email,
          password: password
        }, {
          withCredentials: true
        })
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: "SET_USER",
              payload: response.data
            });

            resolve('User is now logged in');
          } else {
            reject('User could not be logged in');
            throw "Could not login the user";
          }
        })
        .catch((err) => {
          reject(err.message);
          console.log(err);
        });
    });
  }
}

export function logout() {
  return function(dispatch) {
    axios
      .get(`${process.env.REACT_APP_IFSCAPITAL_TWILIO_API_ENDPOINT}/user/logout`, {
        withCredentials: true
      })
      .then(() => {
        dispatch({ type: "UNSET_USER" });
      })
      .catch(err => console.log(err))
  }
}
