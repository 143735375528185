import { SET_USER, UNSET_USER } from "../common/constants";

function user(state = {}, action) {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        ...action.payload
      }
    case UNSET_USER:
      return {};
    default:
      return state;
  }
}

export default user;
