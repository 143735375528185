import { SET_ACTIVE_CONVERSATION_ID } from "../common/constants";

function activeConversationId(state = null, action) {
  switch (action.type) {
    case SET_ACTIVE_CONVERSATION_ID:
      // Test for valid UUID v4
      // /^[0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i
      return action.payload;
    default:
      return state;
  }
}

export default activeConversationId;
