import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import ReactNotification from 'react-notifications-component';
import ProtectedRoute from './ProtectedRoute';
import MessagesPage from './MessagesPage';
import UsersPage from './UsersPage';
import 'react-notifications-component/dist/theme.css'

function App() {
  return (
    <Router>
      <div>
        <ReactNotification />
        <Route exact path="/" component={MessagesPage} />
        <ProtectedRoute path="/users" component={UsersPage} />
      </div>
    </Router>
  );
}

export default App;
