import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import empty from 'is-empty';

const ProtectedRoute = ({ user, component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={
        (props) => {
          if (!empty(user)) {
            return <Component {...props} />
          } else {
            return <Redirect to={
              {
                pathname: "/",
                state: {
                  from: props.location
                }
              }
            } />
          }
        }
      }
    />
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.user
  }
}

export default connect(mapStateToProps)(ProtectedRoute);
