import _ from 'lodash';
import {
  SET_CONVERSATIONS,
  REMOVE_MESSAGES_CONVERSATION,
  SET_CONVERSATION_AS_READ
} from "../common/constants";

function conversations(state = [], action) {
  let stateCopy = [...state];
  let index = 0;

  switch (action.type) {
    case SET_CONVERSATIONS:
      return [...action.payload];

    case REMOVE_MESSAGES_CONVERSATION:
      return _.filter(stateCopy, (conversation) => {
        return conversation.id != action.payload
      });

    case SET_CONVERSATION_AS_READ:
      index = _.findIndex(stateCopy, (item) => {
        return item.id === action.payload
      });
      stateCopy[index].read_status = "read";
      return stateCopy;

    default:
      return state;
  }
}

export default conversations;
