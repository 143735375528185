import React from 'react';
import '../styles/Sidebar.scss';


class Sidebar extends React.Component {
  render() {
    return (
      <aside className="sidebar">
        {this.props.children}
      </aside>
    );
  }
}

export default Sidebar;
