import _ from 'lodash';
import {
  SET_MESSAGES,
} from "../common/constants";

function messages(state = [], action) {
  switch (action.type) {
    case SET_MESSAGES:
      return [...action.payload];

    default:
      return state;
  }
}

export default messages;
