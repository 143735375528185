import React from 'react';
import customProtocolCheck from "custom-protocol-check";

class Base extends React.Component {
  constructor(props) {
    super(props);
    this.sendFilemakerScript = this.sendFilemakerScript.bind(this);
  }

  /**
   * Run a script on filemaker and check if the filemaker protocol is
   * on the current system.
   * @param {string} file
   * @param {*} script
   * @param {*} param
   */
  sendFilemakerScript(file, script, param) {
    return new Promise((resolve, reject) => {
      console.log(`fmp://$/${file}?script=${script}&param=${param}`);
      customProtocolCheck(`fmp://$/${file}?script=${script}&param=${param}`, () => {
        reject();
      }, () => {
        resolve();
      })
    })
  }
}

export default Base;
