import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Icon,
  Modal,
  Button,
  Form,
  Table
} from "semantic-ui-react";
import axios from 'axios';
import empty from 'is-empty';
import emailValidator from 'email-validator';
import { store } from 'react-notifications-component';
import Base from './Base';
import Sidebar from './Sidebar';
import Toolbar from './Toolbar';
import '../styles/UsersPage.scss';


class UsersPage extends Base {
  constructor(props) {
    super(props);
    this.registerUser = this.registerUser.bind(this);
    this.validateForm = this.validateForm.bind(this);
    this.getUserList = this.getUserList.bind(this);
    this.deleteUser = this.deleteUser.bind(this);

    this.state = {
      userRegisterFormEmail: "",
      userRegisterFormPassword: "",
      userRegisterFormConfirmPassword: "",
      formErrors: {},
      isAddUserModalOpen: false,
      users: []
    }
  }

  componentDidMount() {
    this.getUserList();
  }

  registerUser() {
    if (this.validateForm()) {
      axios
        .post(`${process.env.REACT_APP_IFSCAPITAL_TWILIO_API_ENDPOINT}/user/register`, {
          email: this.state.userRegisterFormEmail,
          password: this.state.userRegisterFormPassword
        }, {
          withCredentials: true
        })
        .then(response => {
          if (response.status === 201) {
            store.addNotification({
              title: "Success!",
              message: `User ${this.state.userRegisterFormEmail} has been created`,
              type: "success",
              insert: "top",
              container: "top-right",
              animationIn: ["animated", "fadeIn"],
              animationOut: ["animated", "fadeOut"],
              dismiss: {
                duration: 5000,
                onScreen: true
              }
            });

            this.setState(prevState => {
              return {
                userRegisterFormEmail: "",
                userRegisterFormPassword: "",
                isAddUserModalOpen: false,
                users: [...prevState.users, { email: this.state.userRegisterFormEmail }]
              }
            });
          }
        })
        .catch(err => {
          store.addNotification({
            title: "Error!",
            message: `User ${this.state.userRegisterFormEmail} could not be created`,
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: true
            }
          });
        });
    }
  }

  /**
   * Delete user by email
   * @param {string} email
   */
  deleteUser(email) {
    axios
      .delete(`${process.env.REACT_APP_IFSCAPITAL_TWILIO_API_ENDPOINT}/user/${email}`, {
        withCredentials: true
      })
      .then((response) => {
        if (response.status === 200) {
          store.addNotification({
            title: "Success!",
            message: `User ${email} was successfully deleted`,
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: true
            }
          });

          const users = this.state.users;
          const matchIndex = users.findIndex((user) => {
            return user.email === email;
          });

          if (matchIndex !== -1) {
            users.splice(matchIndex, 1);
          }

          this.setState({
            users
          });
        }
      })
      .catch((err) => {
        store.addNotification({
          title: "Error!",
          message: `User ${email} could not be deleted`,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        });
      })
  }

  getUserList() {
    axios
      .get(`${process.env.REACT_APP_IFSCAPITAL_TWILIO_API_ENDPOINT}/user/list`, {
        withCredentials: true
      })
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            users: response.data
          });
        }
      })
      .catch(err => {
        console.log(err);
      })
  }

  validateForm() {
    let errors = {};
    this.setState({ formErrors: {} });

    if (empty(this.state.userRegisterFormEmail)) {
      errors['userRegisterFormEmail'] = "Field required";
    }

    if (!emailValidator.validate(this.state.userRegisterFormEmail)) {
      errors['userRegisterFormEmail'] = "Please enter a valid email";
    }

    if (empty(this.state.userRegisterFormPassword)) {
      errors['userRegisterFormPassword'] = "Field required";
    }

    if (this.state.userRegisterFormPassword !== this.state.userRegisterFormConfirmPassword) {
      errors['userRegisterFormPassword'] = "Passwords do not match";
    }

    this.setState({
      formErrors: errors
    });

    return empty(errors);
  }

  render() {
    return (
      <div className="users-page">
        <Sidebar>
          <Link to="/">
            <Icon name="arrow left" />
            Back to Messages
          </Link>
        </Sidebar>

        <main>
          <Toolbar>
            <div className="left-side"></div>
            <div className="right-side">
              {this.props.user.type === "admin" && (
                <Button
                  secondary
                  onClick={() => {
                    this.setState({
                      isAddUserModalOpen: true
                    });
                  }}
                >
                  Add User
                </Button>
              )}
            </div>
          </Toolbar>

          <div className="inner">
            <h2>Users</h2>

            <Table>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Email</Table.HeaderCell>
                  <Table.HeaderCell>Actions</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {this.state.users.map(user => {
                  return (
                    <Table.Row>
                      <Table.Cell>{user.email}</Table.Cell>
                      <Table.Cell>
                        {(user.email !== "james@ifscapital.com"
                          && this.props.user.type === "admin")
                            && (
                          <a
                            href="#"
                            onClick={() => {
                              this.deleteUser(user.email);
                            }}
                          >
                            Delete
                          </a>
                        )}
                      </Table.Cell>
                    </Table.Row>
                  )
                })}
              </Table.Body>
            </Table>
          </div>
        </main>

        <Modal open={this.state.isAddUserModalOpen} size="tiny">
          <Modal.Header>Add User</Modal.Header>
          <Modal.Content>
            <Form>
              <Form.Field>
                <label>Email</label>
                <input
                  type="text"
                  onChange={(e) => {
                    this.setState({
                      userRegisterFormEmail: e.target.value
                    });
                  }}
                />
              </Form.Field>
              <Form.Field>
                <label>Password</label>
                <input
                  type="password"
                  onChange={(e) => {
                    this.setState({
                      userRegisterFormPassword: e.target.value
                    });
                  }}
                />
              </Form.Field>
              <Form.Field>
                <label>Confirm Password</label>
                <input
                  type="password"
                  onChange={(e) => {
                    this.setState({
                      userRegisterFormConfirmPassword: e.target.value
                    });
                  }}
                />
              </Form.Field>
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <Button
              secondary
              onClick={this.registerUser}
            >
              Register
            </Button>
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user
  }
}

export default connect(mapStateToProps)(UsersPage);
